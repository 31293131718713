import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Link } from "react-router-dom"

function FAQ() {
    const { t } = useTranslation();

    const [q1Expanded, setQ1Expanded] = useState(false);
    const q1Clicked = () => {
        setQ1Expanded(!q1Expanded)
    }
    const [q2Expanded, setQ2Expanded] = useState(false);
    const q2Clicked = () => {
        setQ2Expanded(!q2Expanded)
    }
    const [q3Expanded, setQ3Expanded] = useState(false);
    const q3Clicked = () => {
        setQ3Expanded(!q3Expanded)
    }
    const [q4Expanded, setQ4Expanded] = useState(false);
    const q4Clicked = () => {
        setQ4Expanded(!q4Expanded)
    }
    const [q5Expanded, setQ5Expanded] = useState(false);
    const q5Clicked = () => {
        setQ5Expanded(!q5Expanded)
    }
    const [q6Expanded, setQ6Expanded] = useState(false);
    const q6Clicked = () => {
        setQ6Expanded(!q6Expanded)
    }
    const [q7Expanded, setQ7Expanded] = useState(false);
    const q7Clicked = () => {
        setQ7Expanded(!q7Expanded)
    }
    const [q8Expanded, setQ8Expanded] = useState(false);
    const q8Clicked = () => {
        setQ8Expanded(!q8Expanded)
    }
    const [q9Expanded, setQ9Expanded] = useState(false);
    const q9Clicked = () => {
        setQ9Expanded(!q9Expanded)
    }

    const handleIconClick = (e, toggleFunction) => {
        e.stopPropagation();
        toggleFunction();
    };

    return (
        <section id="faqs" className="faqs">
            <div className="faq-content">
                <h2>{t('faqs')}</h2>
                <div className="faq-display">
                    <div className="faq-question-container" onClick={q1Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>What platforms does Validia work with?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q1Clicked)}
                            >
                                {q1Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q1Expanded && <p className="faq-response">Validia works with Zoom, Microsoft Teams, Slack, and Webex!</p>}

                    <div className="faq-question-container" onClick={q2Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>What enterprise products does Validia integrate with?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q2Clicked)}
                            >
                                {q2Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q2Expanded && <>
                        <p className="faq-response">Calendars: Outlook and Google</p>
                        <p className="faq-response">HR Systems: Over 50 platform integrations! See this <Link to="https://www.merge.dev/integrations?api-category=HRIS">link</Link> for the full list.</p>
                        <p className="faq-response">ATS Systems: Over 25 platform integrations! See this <Link to="https://www.merge.dev/integrations?api-category=ATS">link</Link> for the full list.</p>
                    </>}

                    <div className="faq-question-container" onClick={q3Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>What is a Bioprint?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q3Clicked)}
                            >
                                {q3Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q3Expanded && <p className="faq-response">A Bioprint is Validia's unique facial and voice profile of an employee. It's created from a single image and short voice sample, and used for real-time identity verification during video and audio calls.</p>}

                    <div className="faq-question-container" onClick={q4Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>What about Privacy?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q4Clicked)}
                            >
                                {q4Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q4Expanded && <>
                        <p className="faq-response">Validia is SOC-II Type I compliant. The Type II audit is currently in progress. Our Bioprint technology offers flexible privacy options:</p>
                        <p className="faq-list-item">1. At the organization's request, we can operate without storing any raw data from facial images or voice samples.</p>
                        <p className="faq-list-item">2. Alternatively, we can store this data for observability purposes if the organization prefers.</p>
                        <p className="faq-response">In either case, we only use irreversible embeddings for authentication. These embeddings cannot be converted back into original samples or used outside our platform, ensuring maximum security.</p>
                    </>}

                    <div className="faq-question-container" onClick={q5Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>Do you offer on-premises solutions?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q5Clicked)}
                            >
                                {q5Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q5Expanded && <p className="faq-response">Yes, we have on-premises options in beta for clients with specific security needs.</p>}

                    <div className="faq-question-container" onClick={q6Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>Does Validia store call recordings?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q6Clicked)}
                            >
                                {q6Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q6Expanded && <p className="faq-response">No, Validia adheres to strict data privacy standards. The company does not store or use any call recordings.</p>}

                    <div className="faq-question-container" onClick={q7Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>How flexible is Validia in terms of customization for specific organizational needs?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q7Clicked)}
                            >
                                {q7Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q7Expanded && <p className="faq-response">Validia is highly customizable. You can set permissions at both organization and user levels, and turn features on or off based on your needs.</p>}

                    <div className="faq-question-container" onClick={q8Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>How does Validia protect against deepfakes?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q8Clicked)}
                            >
                                {q8Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q8Expanded && <p className="faq-response">KYE verifies identities in real-time during calls. Jam corrupts the outputs of AI models, preventing them from accurately replicating protected digital assets.</p>}

                    <div className="faq-question-container" onClick={q9Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>Is Validia compatible with our existing security systems?</span>
                            <IconButton 
                                aria-label="Toggle answer" 
                                onClick={(e) => handleIconClick(e, q9Clicked)}
                            >
                                {q9Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q9Expanded && <p className="faq-response">Yes, Validia is designed to integrate with most existing enterprise security systems.</p>}
                </div>
            </div>
        </section>
    );
}

export default FAQ;
