import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export function ScrollToAnchor({ behavior = 'smooth' }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      
      if (element) {
        // Scroll to element if it exists
        element.scrollIntoView({ behavior });
      } else {
        // Remove hash if element doesn't exist
        navigate(location.pathname, { replace: true });
      }
    } else {
      // Scroll to top when navigating to a new page without hash
      window.scrollTo({ top: 0, behavior });
    }
  }, [location, navigate, behavior]);

  return null;
}

ScrollToAnchor.propTypes = {
  behavior: PropTypes.oneOf(['auto', 'smooth'])
};

export default ScrollToAnchor;
