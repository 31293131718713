import React, { useEffect } from "react";

export default function TermsPolicy() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="w-full flex justify-center">
      <div 
        className="w-3/4"
        name="termly-embed"
        data-id="970ce6ce-a4fb-4f2e-b3af-cac13b1b668f"
        data-type="iframe"
      ></div>
    </div>
  );
}
