import React from 'react';
import './BelowHeaderBanner.css';
import { Trophy } from 'lucide-react';

function BelowHeaderBanner() {

    return (
        <div className="below-header-banner">
            <Trophy className="hidden-on-mobile" style={{ height: '16px', width: '16px', color: 'white', display: 'block', marginRight: '5px' }} />
            Validia wins Okta's SaaS Startup Competition
            <button onClick={() => window.location.href = 'https://validia.ai/blog/validia-wins-okta-competition'}>Learn more</button>
        </div>
    );
}

export default BelowHeaderBanner;
