import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import './ScheduleDemoPage.css';

function ScheduleDemoPage() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    image: null
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'image' ? files[0] : value
    }));
  };


  return (
    <div className="schedule-demo-wrapper">
      <Helmet>
        <title>Validia - {t('scheduleDemo')}</title>
        <meta name="description" content="Schedule a deepfake demo with Validia and see how we protect against AI impersonation." />
      </Helmet>
      <div className="schedule-demo-page">
        <div className="demo-content">
          <h1 className="demo-title">{t('scheduleDemoTitle')}</h1>
          <p className="demo-subtitle">{t('scheduleDemoSubtitle')}</p>
          <div className="demo-layout">
            <div className="video-wrapper">
              <video autoPlay loop muted playsInline>
                <source src="demo.mov" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="form-wrapper">
              <h2>{t('scheduleDemoFormTitle')}</h2>
              <form action="https://formspree.io/f/mvgprllk" method="POST" enctype="multipart/form-data" className="demo-form">
                <div className="form-grid">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    placeholder={t('formName')}
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    placeholder={t('formEmail')}
                  />
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    required
                    placeholder={t('formCompany')}
                  />
                  <div className="file-input-wrapper">
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      onChange={handleInputChange}
                    />
                    <label htmlFor="image" className="file-input-label">
                      {formData.image ? formData.image.name : t('formImage')}
                    </label>
                  </div>
                </div>
                <button type="submit" className="submit-button">
                  {t('formSubmit')}
                  <span className="button-arrow">→</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleDemoPage;
