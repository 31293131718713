import React from 'react';
import { Link } from 'react-router-dom';
import './ThankYou.css';

function ThankYou() {
  return (
    <div className="thank-you-container">
      <h1>Thank You for Submitting!</h1>
      <p>A Validia representative will be in touch soon.</p>
      <Link to="/" className="home-link">Return to Home</Link>
    </div>
  );
}

export default ThankYou;
