import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/Home';
import ScheduleDemoPage from './components/ScheduleDemo';
import ThankYou from './components/ThankYou';
import BlogPage from './components/NewBlogList';
import BlogPost from './components/NewBlogPost';
import Policy from './components/PolicyComponent';
import TermsPolicy from './components/TermsComponent';
import ScrollToAnchor from './components/ScrollToAnchor';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// 404 Component with larger robot SVG
const NotFound = () => {
  return (
    <div style={{ minHeight: '60vh', paddingBottom: '100px', paddingTop: '120px' }} className="relative">
      <div className="w-full text-center px-4">
        <svg
          viewBox="0 0 200 200"
          style={{ width: '240px', height: '240px', margin: '0 auto', marginBottom: '16px' }}
        >
          {/* Fun robot face */}
          <g transform="translate(100 100)">
            {/* Robot head */}
            <rect
              x="-50"
              y="-60"
              width="100"
              height="120"
              rx="10"
              fill="#e0e0e0"
              stroke="#333"
              strokeWidth="4"
            />

            {/* Antenna */}
            <line
              x1="0"
              y1="-60"
              x2="0"
              y2="-80"
              stroke="#333"
              strokeWidth="4"
            />
            <circle
              cx="0"
              cy="-85"
              r="5"
              fill="#ff6b6b"
            />

            {/* Eyes */}
            <g className="eyes">
              <circle
                cx="-20"
                cy="-20"
                r="12"
                fill="#333"
              >
                <animate
                  attributeName="r"
                  values="12;8;12"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </circle>
              <circle
                cx="20"
                cy="-20"
                r="12"
                fill="#333"
              >
                <animate
                  attributeName="r"
                  values="12;8;12"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>

            {/* Mouth */}
            <path
              d="M-20 20 Q0 40 20 20"
              fill="none"
              stroke="#333"
              strokeWidth="4"
              strokeLinecap="round"
            />

            {/* Error message */}
            <text
              x="0"
              y="60"
              textAnchor="middle"
              fill="#333"
              fontSize="16"
              fontFamily="monospace"
            >
              &lt;404/&gt;
            </text>
          </g>
        </svg>

        <h1 className="text-6xl font-bold text-gray-900 mb-4">Oops!</h1>
        <p className="text-xl text-gray-600 mb-4">Looks like this page took a wrong turn.</p>
        <p className="text-lg text-gray-500 mb-8">Don't worry, even robots get lost sometimes!</p>
        <a
          href="/"
          className="inline-block px-6 py-3 text-lg text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          Take Me Home
        </a>
      </div>
    </div>
  );
};

// External redirect component
const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);
  return <div>Redirecting...</div>;
};

function App() {
  return (
    <Router>
      < ScrollToAnchor />
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/demo" element={<ScheduleDemoPage />} />
          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/terms" element={<TermsPolicy />} />
          <Route path="/privacy" element={<Policy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
