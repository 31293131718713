import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import FlagLanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const handleNavClick = (sectionId) => {
    if (location.pathname !== '/') {
      window.location.href = `/#${sectionId}`;
      return;
    }

    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  return (
    <header className="App-header">
      <div className="logo-container">
        <Link to="/">
          <img src="/validia_logo.png" alt="Validia Logo" className="logo" />
        </Link>
      </div>
      <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)} aria-label="Toggle menu">
        {menuOpen ? <FaTimes /> : <FaBars />}
      </button>
      <nav className={menuOpen ? 'show' : ''}>
        <ul className={`nav-list ${menuOpen ? 'mobile-menu' : ''}`}>
          <li><a onClick={() => handleNavClick('product')} style={{cursor: 'pointer'}}>{t('product')}</a></li>
          <li><a onClick={() => handleNavClick('company')} style={{cursor: 'pointer'}}>{t('aboutUs')}</a></li>
          <li><a onClick={() => handleNavClick('testimonials')} style={{cursor: 'pointer'}}>{t('testimonials')}</a></li>
          <li><Link to="/blog">{t('blog')}</Link></li>
          <li><a onClick={() => handleNavClick('faqs')} style={{cursor: 'pointer'}}>{t('faqs')}</a></li>
          <li><a onClick={() => handleNavClick('contact')} style={{cursor: 'pointer'}}>{t('contact')}</a></li>
          <li className="mobile-cta">
            <button className="secondary-button" onClick={() => window.location.href = 'https://app.validia.ai'}>{t('signin')}</button>
          </li>
          <li className="mobile-cta">
            <button className="primary-button" onClick={() => window.location.href = '/demo'}>{t('getDemo')}</button>
          </li>
          {menuOpen && <li className="mobile-language-selector"><FlagLanguageSelector /></li>}
        </ul>
      </nav>
      <div className="cta-buttons desktop-cta">
        <Button variant="outlined" disableElevation className="secondary-button" onClick={() => window.location.href = 'https://app.validia.ai'}>{t('signin')}</Button>
        <Button variant="contained" disableElevation className="primary-button" onClick={() => window.location.href = '/demo'}>{t('getDemo')}</Button>
      </div>
      <div className="desktop-language-selector">
        <FlagLanguageSelector />
      </div>
    </header>
  );
}

export default Header;
